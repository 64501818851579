.modal {
	font-family: raleway-vh, sans-serif;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(#000000, 0.8);
	z-index: -10000;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
	overflow-y: auto;

	&__container {
		position: relative;
		background-color: lighten($deep, 6);
		box-shadow: 0 0 0 8px rgba(#fff, 0.1);
		border-radius: 0;
		max-width: 500px;
		padding: 30px;
	}
	&__title {
		font-size: 1.25rem;
		line-height: 1.25;
		display: block;
		width: 100%;
		color: #fff;
		font-family: oswald-vh, sans-serif;
		text-transform: uppercase;
		font-weight: 400;
	}

	&__header {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 20px;
		color: #fff;
		opacity: 0.25;
		transition: opacity 0.25s ease-in;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}

	&__content {
		text-align: center;
		margin-bottom: 20px;
		a {
			color: #fff;
			transition: color 0.25s ease-in;
			text-decoration: none;
			border: none;
			outline: none;
			@media (any-hover) {
				&:hover {
					color: $accent;
					text-decoration: none;
				}
			}
			&:focus {
				outline: none;
			}
		}
	}
	&__footer {
		text-align: center;
		.button {
			cursor: pointer;
		}
	}

	&__input {
		margin-bottom: 8px;
	}

	&.active {
		z-index: 1000000;
		visibility: visible;
		opacity: 1;
		pointer-events: all;
	}
}
