body._lock {
	overflow-x: hidden;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.home-aside {
	background-color: $dark;
	width: $size * 4;
	position: absolute;
	top: $size;
	bottom: $size - 1px;
	left: -208px;
	display: flex;
	justify-content: space-between;
	border-right: 3px solid $dark;
	border-bottom: 1px solid $dark;
	z-index: 15;
	transition: all 0.3s ease-in-out;
	@include media-breakpoint-down(sm) {
		width: $size-sm * 4;
		left: -173px;
		top: $size-sm;
		bottom: $size-sm - 1px;
	}
	&._active {
		border-bottom: 1px solid rgba(#fff, 0.15);
		border-right: 3px solid $accent;
		left: 0;
		@include media-breakpoint-down(sm) {
			left: 0;
			top: $size-sm;
			bottom: $size-sm - 1px;
		}
	}
}

.home-aside__left {
	flex: 1 1 auto;
	max-width: 100%;
	height: 100%;
	background-color: #252525;
}

.home-aside__right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: $size - 3px;
	position: relative;
	height: 100%;
	@include media-breakpoint-down(sm) {
		width: $size-sm - 3px;
	}
}

.menu {
	&__list > li {
		position: relative;
		margin: 0 0 0 20px;
	}

	// .menu__button-container

	&__button-container {
		flex: 0 0 200px;
		text-align: right;
		position: relative;
		height: $size;
		@include media-breakpoint-down(md) {
			flex: 1 0 0;
		}
		@include media-breakpoint-down(sm) {
			height: $size-sm;
		}
	}

	// .menu__button

	&__button {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: $size;
		height: $size;
		background-color: #3c3c3c;
		transition: background 0.25s ease-out;
		cursor: pointer;
		&:hover {
			background-color: $accent;
		}
		@include media-breakpoint-down(sm) {
			width: $size-sm;
			height: $size-sm;
		}
		&._active {
			background-color: $accent;
		}
	}

	// .menu__link

	&__link {
		color: #fff;
		transition: all 0.3s ease-in-out;
		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	// .menu__sub-link

	&__sub-link {
		color: #fff;
		transition: all 0.3s ease-in-out;
		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	// .menu__sub-list

	&__sub-list {
		position: absolute;
		top: 100%;
		right: 0;
		background-color: rgb(223, 40, 230);
		padding: 15px;
		min-width: 200px;
	}

	&__sub-list li {
		margin: 0 0 10px 0;
		&:last-child {
			margin: 0;
		}
	}

	&__arrow {
		display: none;
	}

	&__icon {
		z-index: 5;
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		& span,
		&::before,
		&::after {
			left: 0;
			position: absolute;
			height: 10%;
			width: 100%;
			transition: all 0.3s ease 0s;
			background-color: #fff;
		}
		&::before,
		&::after {
			content: "";
		}
		&::after {
			bottom: 0;
		}
		&::before {
			top: 0;
		}
		span {
			top: 50%;
			transform: scale(1) translate(0px, -50%);
		}

		&._active {
			span {
				transform: scale(0) translate(0px, 50%);
			}
		}
		&._active::before {
			top: 50%;
			transform: rotate(-45deg) translate(0px, -50%);
		}
		&._active::after {
			bottom: 50%;
			transform: rotate(45deg) translate(0px, 50%);
		}
	}
	&__body {
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		height: 100%;
		padding: 10px;
	}

	&__list > li {
		flex-wrap: wrap;
		margin: 0 0 20px 20px;
		&:last-child {
			margin: 0 0 0 20px;
		}
	}

	&__list > li._active &__sub-list {
		display: block;
	}

	&__link {
		font-size: 24px;
	}

	&__sub-list {
		position: relative;
		background-color: rgba(#fff, 0.5);
		flex: 1 1 100%;
		margin: 20px 0 0 0;
		display: none;
	}
	&__sub-link {
		font-size: 20px;
		color: #000;
	}
}

// ------------------------------- //
body._pc .menu__list > li:hover .menu__sub-list {
	opacity: 1;
	visibility: visible;
	transform: translate(0px, 0px);
	pointer-events: all;
}

body._touch .menu__list > li {
	display: flex;
	align-items: center;
}
body._touch .menu__link {
	flex: 1 1 auto;
}
body._touch .menu__arrow {
	display: block;
	width: 0;
	height: 0;
	margin: 0px 0px 0px 5px;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #fff;
	transition: transform 0.3s ease-in-out;
}

body._touch .menu__list > li._active .menu__sub-list {
	opacity: 1;
	visibility: visible;
	transform: translate(0px, 0px);
	pointer-events: all;
}

body._touch .menu__list > li._active .menu__arrow {
	transform: rotate(-180deg);
}

// ------------------------------- //
