.button {
	display: inline-flex;
	background-color: $accent;
	color: #fff;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 14px 26px;
	font-weight: 800;
	position: relative;
	border: none;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $dark;
		top: 0;
		left: 0;
		width: 0;
		transition: width .5s ease-out;
	}
	&:hover, &focus {
		color: #fff;
		text-decoration: none;
		&::before {
			width: 100%;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 12px 18px;
	}
}

.button__text {
	position: relative;
	z-index: 2;
	margin-right: 5px;
	white-space: nowrap;
}
