.form-input {
	display: block;
	background-color: $dark;
	border: lighten($dark, 7) 1px solid;
	padding: 12px 18px;
	min-width: 280px;
	color: #fff;
	font-size: 15px;
	transition: border .25s ease-in;
	&:focus {
		border: lighten($dark, 20) 1px solid;
	}
}

textarea.form-input {
	min-height: 100px;
}