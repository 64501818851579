input:invalid,
textarea:invalid {
  border: 1px dashed red;
}

input:valid,
textarea:valid {
  border: 1px solid lime;
}

.button-edit {
  background-color: #45ac1f;
  transition: background-color 0.25s;
  @media (any-hover: hover) {
    &:hover {
      background-color: #3b931b;
    }
  }
}

.button-delete {
  transition: background-color 0.25s;
  background-color: #f34100;
  @media (any-hover: hover) {
    &:hover {
      background-color: #a42d02;
    }
  }
}
