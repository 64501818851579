// USER VARIABLES SECTION

$accent: #f57500 // Accent Color
$fontsize: 16px // Body Font Size
$textcolor: #333 // Text Color
$dark: #303030
$deep: #252525
$lineheight: 1.7 // Document Line Height
$userfont: raleway-vh, sans-serif // User Font Family
$systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif // System Font Family
$size: 70px
$size-sm: 58px
$border-opacity: .1

// Bootstrap compatible gutter variable => $gutter
$grid-gutter-width: 30px
$gutter: $grid-gutter-width

// IMPORT SECTION

@import 'node_modules/bootstrap/scss/bootstrap-reboot.scss' // Bootstrap Reboot collection
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss' // Bootstrap Breakpoints mixin
// @import 'node_modules/bootstrap/scss/bootstrap-grid.scss' // Optional Bootstrap Grid
// @import 'node_modules/bootstrap/scss/utilities/_sizing.scss' // Optional Bootstrap Utilites

@import "node_modules/swiper/swiper-bundle"

//@import "app/vendor/micromodal/micromodal"

// FONTS LOAD SECTION

@mixin font($fname, $fstyle, $fweight, $furl)
	@font-face
		font-family: $fname
		font-style: $fstyle
		font-weight: $fweight
		src: url($furl) format('woff2')

@include font('oswald-vh', normal, 700, '../assets/fonts/oswaldbold-webfont.woff2')
@include font('oswald-vh', normal, 500, '../assets/fonts/oswaldmedium-webfont.woff2')
@include font('raleway-vh', normal, 800, '../assets/fonts/ralewayextrabold-webfont.woff2')
@include font('raleway-vh', normal, 500, '../assets/fonts/ralewaymedium-webfont.woff2')
@include font('raleway-vh', normal, 400, '../assets/fonts/ralewayregular-webfont.woff2')
@include font('raleway-vh', normal, 600, '../assets/fonts/ralewaysemibold-webfont.woff2')
@include font('roboto-vh', normal, 400, '../assets/fonts/robotoregular-webfont.woff2')

// GENERAL DOCUMENT STYLES

::placeholder
	color: #666

::selection
	background-color: $accent
	color: #fff

input, textarea
	outline: none
	&:focus:required:invalid
		border-color: red
	&:required:valid
		border-color: green

body
	font-family: $userfont
	font-size: $fontsize
	line-height: $lineheight
	color: $textcolor
	min-width: 320px
	position: relative
	overflow-x: hidden
	-ms-overflow-style: none
	scrollbar-width: none
	&::-webkit-scrollbar 
		width: 0
		height: 0
	
.__container
	position: relative
	max-width: 1240px
	margin: 0 auto

a
	text-decoration: none